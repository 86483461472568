import React from 'react';
import './index.scss';

export const ButtonLink = ({
  text,
  icon,
  to,
  size = 'default',
  target,
}: {
  text: string;
  to: string;
  icon?: string;
  size?: 'default' | 'large';
  target: string;
}) => (
  <a
    onClick={() => {
      // @ts-ignore
      window.ym('67519351', 'reachGoal', target);
      return true;
    }}
    href={to}
    className={`button button-${size}`}
  >
    {icon ? <img className="button_icon" src={icon} alt="icon" /> : null}
    <span className="button_text">{text}</span>
  </a>
);
