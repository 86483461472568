import React, { PureComponent, RefObject } from 'react';
import Slider from 'react-slick';

import slide1 from '../../assets/images/slide_1.png';
import slide2 from '../../assets/images/slide_2.png';
import slide3 from '../../assets/images/slide_3.png';
import slide4 from '../../assets/images/slide_4.png';
import slide5 from '../../assets/images/slide_5.png';

import slide1Icon from '../../assets/svg/slide_1_icon.svg';
import slide2Icon from '../../assets/svg/slide_2_icon.svg';
import slide3Icon from '../../assets/svg/slide_3_icon.svg';
import slide4Icon from '../../assets/svg/slide_4_icon.svg';
import slide5Icon from '../../assets/svg/slide_5_icon.svg';

import 'slick-carousel/slick/slick.css';
import './index.scss';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export class Slides extends PureComponent {
  state = {
    currentSlide: 0,
  };

  counter: RefObject<HTMLDivElement> = React.createRef();

  componentDidMount = () => {
    this.changeCounterPosition();
    window.onresize = () => setTimeout(this.changeCounterPosition, 100);
  };

  changeSlide = (index: number): void => this.setState({ currentSlide: index });

  changeCounterPosition = (): void => {
    const counterElement = this.counter.current;
    const buttons = document.querySelectorAll('.slick-arrow') as NodeListOf<HTMLElement>;
    const slideImageElement = document.querySelector('.slides__slide img');

    if (window.innerWidth > 992 && counterElement) {
      counterElement.style.top = '';
      buttons.forEach((button) => {
        button.style.top = '';
      });

      return;
    }

    if (counterElement) {
      if (slideImageElement) {
        const top = slideImageElement.getBoundingClientRect().height + 20;
        counterElement.style.opacity = '1';
        counterElement.style.top = top + 'px';
        buttons.forEach((button) => {
          button.style.top = top + 15 + 'px';
        });
      }
    }
  };

  render() {
    const {
      state: { currentSlide },
      changeSlide,
      changeCounterPosition,
      counter,
    } = this;

    return (
      <div className="slides">
        <div className="container">
          <div className="row">
            <div className="col-12 offset-0 col-lg-10 offset-lg-1">
              <div className="slides__backgroundCounter">
                <span>0{currentSlide + 1}</span>
              </div>
              <Slider afterChange={(index) => changeSlide(index)} {...settings}>
                <div className="slides__slide">
                  <img onLoad={() => changeCounterPosition()} src={slide1} alt="dashboard" />
                  <div className="slides__slide__info">
                    <img src={slide1Icon} alt="humans" />
                    <span className="slides__slide__info_text">
                      <b>Автоматизируйте</b> поощрение выполнения целевых активностей и плановых показателей
                    </span>
                  </div>
                </div>
                <div className="slides__slide">
                  <img src={slide2} alt="shop" />
                  <div className="slides__slide__info">
                    <img src={slide2Icon} alt="gift" />
                    <span className="slides__slide__info_text">
                      <b>Наполняйте</b> корпоративную витрину материальными и нематериальными вознаграждениями
                    </span>
                  </div>
                </div>
                <div className="slides__slide">
                  <img src={slide3} alt="hero" />
                  <div className="slides__slide__info">
                    <img src={slide3Icon} alt="hero" />
                    <span className="slides__slide__info_text">
                      <b>Настраивайте</b> уровни прокачки персонального героя или территории сотрудника
                    </span>
                  </div>
                </div>
                <div className="slides__slide">
                  <img src={slide4} alt="game" />
                  <div className="slides__slide__info">
                    <img src={slide4Icon} alt="game" />
                    <span className="slides__slide__info_text">
                      <b>Запускайте</b> командные и индивидуальные соревнования
                    </span>
                  </div>
                </div>
                <div className="slides__slide">
                  <img src={slide5} alt="achievement" />
                  <div className="slides__slide__info">
                    <img src={slide5Icon} alt="achievement" />
                    <span className="slides__slide__info_text">
                      <b>Добавляйте</b> достижения и коронки
                    </span>
                  </div>
                </div>
              </Slider>

              <span ref={counter} className="slides_counter">
                <span className="slides_counter-current">0{currentSlide + 1}</span>
                <span className="slides_counter-total">/ 05</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
