import React from 'react';
import { Logo } from '../logo';
// import { ButtonLink } from '../buttonLink';
import mailIcon from '../../assets/svg/mail.svg';
import './index.scss';

export const region = 'RU';//window.location.host === 'gamelab24.by' ? 'BY' : 'RU';

const privacy = {
  RU: './privacy.pdf',
  // BY: './privacy_by.pdf'
}[region];

const agreement = {
  RU: './agreement.pdf',
  // BY: './agreement_by.pdf'
}[region];

// export const bitrixUrl = {
//   RU: 'https://www.bitrix24.ru/apps/?app=gamificationlab.app',
//   // BY: 'https://www.bitrix24.by/apps/?app=gamificationlab.gamelab24_by',
// }[region];


export const platformUrl = {
  RU: 'https://app.gamelab24.com/',
  // BY: 'https://app.gamelab24.by/',
}[region];

// const Button = () => (
//   <ButtonLink target="install" text="Установить решение" to={bitrixUrl} />
// );

export const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-12 footer__column-1">
          <Logo />
          <a className="footer_link-more" target="_blank" rel="noreferrer noopener" href="https://gamificationlab.com/">
            Узнать о других продуктах
          </a>
          {/*<Button />*/}
        </div>
        <div className="col-lg-3 col-12 footer__column-2">
          <a className="footer_link" target="_blank" rel="noopener noreferrer" href={privacy}>
            Политика конфиденциальности
          </a>
        </div>
        <div className="col-lg-3 col-12 footer__column-3">
          <span className="footer__mail">
            <img className="footer__mail_icon" src={mailIcon} alt="mail" />
            <a href="mailto:bitrix@gamelab24.com">bitrix@gamelab24.com</a>
          </span>
          <a className="footer_link" target="_blank" rel="noopener noreferrer" href={agreement}>
            Пользовательское соглашение
          </a>
        </div>
        <div className="col-lg-3 col-12 footer__column-4">
          {/*<Button />*/}
          <span className="text-muted">Gamification Lab © {new Date().getFullYear()}</span>
        </div>
      </div>
    </div>
  </div>
);
