import React from 'react';
import { Logo } from '../logo';
import { ButtonLink } from '../buttonLink';
import './index.scss';
import {platformUrl} from "../footer";
import signin from '../../assets/svg/signin.svg';

export function Header() {
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__container">
              <Logo />
              <ButtonLink
                target="Войти"
                to={platformUrl}
                icon={signin}
                text="Войти"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
