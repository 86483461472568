import React from 'react';
import ReactDOM from 'react-dom';
import { Header } from './components/header';
import { Main } from './components/main';
import { Slides } from './components/slides';
import { Footer } from './components/footer';
import './assets/scss/template.scss';

function Gamification() {
  return (
    <div className="gamification">
      <Header />
      <Main />
      <Slides />
      <Footer />
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Gamification />
  </React.StrictMode>,
  document.getElementById('root')
);
