import React from 'react';
// import { ButtonLink } from '../buttonLink';
import bitrixLogo from '../../assets/svg/bitrix.svg';
import './index.scss';
// import {bitrixUrl} from "../footer";

export const Main = () => (
  <React.Fragment>
    <div className="main">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 pl-0 pr-0">
            <div className="main__left">
              <div className="main__left__content">
                <h2 className="main__left_title">
                  Увеличивайте <span className="text-primary">эффективность</span>
                  <br />
                  <span className="main__left_title-sub">Ваших сотрудников</span>
                </h2>
                <span className="main__left_text">
                  Приложение корпоративной геймификации для{' '}
                  <img className="main__left_text_logo" src={bitrixLogo} alt="Битрикс 24" />
                </span>
                {/*<ButtonLink*/}
                {/*  size="large"*/}
                {/*  text="Попробовать бесплатно"*/}
                {/*  to={bitrixUrl}*/}
                {/*  target="trial"*/}
                {/*/>*/}
              </div>

              <div className="main__left__bottom d-none d-lg-flex">
                <h3 className="main__left__bottom_title">
                  Интересные задачи <span className="main__left__bottom_title-sub">мотивируют</span>
                </h3>
              </div>

              <div className="main__left_notebook" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="main__left__bottom d-flex d-lg-none">
      <h3 className="main__left__bottom_title">
        Интересные задачи <span className="main__left__bottom_title-sub">мотивируют</span>
      </h3>
    </div>
  </React.Fragment>
);
